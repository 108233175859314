import { Box, Typography, Card, CardContent } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SupraTVHeader from '../components/SupraTVHeader';

import Grid from '@mui/material/Grid2';
import axios from 'axios';
import React from 'react';
import SupraTVFrontImage from '../components/SupraTVFrontImage';

const APIURL = process.env.REACT_APP_API_URL;

function All() {
    const token = sessionStorage.getItem("supratvtoken") || "";
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [series, setSeries] = useState<any[]>([]);

    function gettvseries() {
        const formData = new FormData();
        formData.append('token', token);

        axios.post(APIURL + "/supratv/listseries", formData, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setSeries(response.data);
                    console.log(response.data);
                }
                else {
                }
                setLoading(false);
            })
            .catch((e: Error) => {
                setLoading(false);
            });
    }

    const handleSelectTVSeries = (item: number) => {
        navigate("/series/" + item);
    };

    useEffect(() => {
        if (!sessionStorage.getItem('supratvtoken')) {
            navigate('/login');
        }
        gettvseries();
        setLoading(false);
    }, [navigate]);


    // Scroll new series
    const scrollRefNew = useRef<HTMLDivElement>(null);
    const [isDraggingNew, setIsDraggingNew] = useState(false);
    const [startXNew, setStartXNew] = useState(0);
    const [scrollLeftNew, setScrollLeftNew] = useState(0);
    const handleMouseDownNew = (e: React.MouseEvent) => {
        if (!scrollRefNew.current) return;
        setIsDraggingNew(true);
        setStartXNew(e.pageX - scrollRefNew.current.offsetLeft);
        setScrollLeftNew(scrollRefNew.current.scrollLeft);
    };
    const handleMouseLeaveNew = () => {
        setIsDraggingNew(false);
    };
    const handleMouseUpNew = () => {
        setIsDraggingNew(false);
    };
    const handleMouseMoveNew = (e: React.MouseEvent) => {
        if (!isDraggingNew || !scrollRefNew.current) return;
        e.preventDefault();
        const x = e.pageX - scrollRefNew.current.offsetLeft;
        const walk = (x - startXNew) * 2;
        scrollRefNew.current.scrollLeft = scrollLeftNew - walk;
    };

    // Scroll new episodes
    const scrollRefNewEpisodes = useRef<HTMLDivElement>(null);
    const [isDraggingNewEpisodes, setIsDraggingNewEpisodes] = useState(false);
    const [startXNewEpisodes, setStartXNewEpisodes] = useState(0);
    const [scrollLeftNewEpisodes, setScrollLeftNewEpisodes] = useState(0);
    const handleMouseDownNewEpisodes = (e: React.MouseEvent) => {
        if (!scrollRefNewEpisodes.current) return;
        setIsDraggingNewEpisodes(true);
        setStartXNewEpisodes(e.pageX - scrollRefNewEpisodes.current.offsetLeft);
        setScrollLeftNewEpisodes(scrollRefNewEpisodes.current.scrollLeft);
    };
    const handleMouseLeaveNewEpisodes = () => {
        setIsDraggingNewEpisodes(false);
    };
    const handleMouseUpNewEpisodes = () => {
        setIsDraggingNewEpisodes(false);
    };
    const handleMouseMoveNewEpisodes = (e: React.MouseEvent) => {
        if (!isDraggingNewEpisodes || !scrollRefNewEpisodes.current) return;
        e.preventDefault();
        const x = e.pageX - scrollRefNewEpisodes.current.offsetLeft;
        const walk = (x - startXNewEpisodes) * 2;
        scrollRefNewEpisodes.current.scrollLeft = scrollLeftNewEpisodes - walk;
    };

    // Scroll Norwegian
    const scrollRefNewNor = useRef<HTMLDivElement>(null);
    const [isDraggingNewNor, setIsDraggingNewNor] = useState(false);
    const [startXNewNor, setStartXNewNor] = useState(0);
    const [scrollLeftNewNor, setScrollLeftNewNor] = useState(0);
    const handleMouseDownNewNor = (e: React.MouseEvent) => {
        if (!scrollRefNewNor.current) return;
        setIsDraggingNewNor(true);
        setStartXNewNor(e.pageX - scrollRefNewNor.current.offsetLeft);
        setScrollLeftNewNor(scrollRefNewNor.current.scrollLeft);
    };
    const handleMouseLeaveNewNor = () => {
        setIsDraggingNewNor(false);
    };
    const handleMouseUpNewNor = () => {
        setIsDraggingNewNor(false);
    };
    const handleMouseMoveNewNor = (e: React.MouseEvent) => {
        if (!isDraggingNewNor || !scrollRefNewNor.current) return;
        e.preventDefault();
        const x = e.pageX - scrollRefNewNor.current.offsetLeft;
        const walk = (x - startXNewNor) * 2;
        scrollRefNewNor.current.scrollLeft = scrollLeftNewNor - walk;
    };

    // Scroll Paranormal
    const scrollRefNewPara = useRef<HTMLDivElement>(null);
    const [isDraggingNewPara, setIsDraggingNewPara] = useState(false);
    const [startXNewPara, setStartXNewPara] = useState(0);
    const [scrollLeftNewPara, setScrollLeftNewPara] = useState(0);
    const handleMouseDownNewPara = (e: React.MouseEvent) => {
        if (!scrollRefNewPara.current) return;
        setIsDraggingNewPara(true);
        setStartXNewPara(e.pageX - scrollRefNewPara.current.offsetLeft);
        setScrollLeftNewPara(scrollRefNewPara.current.scrollLeft);
    };
    const handleMouseLeaveNewPara = () => {
        setIsDraggingNewPara(false);
    };
    const handleMouseUpNewPara = () => {
        setIsDraggingNewPara(false);
    };
    const handleMouseMoveNewPara = (e: React.MouseEvent) => {
        if (!isDraggingNewPara || !scrollRefNewPara.current) return;
        e.preventDefault();
        const x = e.pageX - scrollRefNewPara.current.offsetLeft;
        const walk = (x - startXNewPara) * 2;
        scrollRefNewPara.current.scrollLeft = scrollLeftNewPara - walk;
    };

    return (
        <>
            <SupraTVHeader />
            <Grid container sx={{ ml: 2, mt: 2 }}>
                <Grid>
                    <Typography variant="h6" component="h2">
                        Alle serier
                    </Typography>
                    <Grid container sx={{ ml: 2, mt: 2 }}>
                        {series[0]?.series && series[0]?.series.map(({ tvsid, tvsname }: any, index: number) => {
                            return <Grid size={{ xs: 2, md: 2 }} sx={{ p: 2 }}>
                                <Card
                                    key={"newep" + index}
                                    onClick={() => handleSelectTVSeries(tvsid)}
                                    sx={{
                                        minWidth: '250px',
                                        cursor: 'pointer',
                                        flexShrink: 0,
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                >
                                    <SupraTVFrontImage
                                        src={"https://media.ghosthunters.no/tv/front/" + tvsid + ".jpg"}
                                        alt={tvsname}
                                        fallback="images/front/unknown.jpg"
                                        overlayText={tvsname}
                                    />
                                    <CardContent>
                                        <Typography variant="caption">{tvsname}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default All;