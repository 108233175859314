import React, { useRef, useState } from 'react';
import { Box, BoxProps } from '@mui/material';

interface HorizontalScrollProps extends BoxProps {
  children: React.ReactNode;
}

const SupraTVHorizontalScroll: React.FC<HorizontalScrollProps> = ({ children, ...props }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDraggingNew] = useState(false);
  const [startX, setStartXNew] = useState(0);
  const [scrollLeft, setScrollLeftNew] = useState(0);
  const handleMouseDownNew = (e: React.MouseEvent) => {
    if (!scrollRef.current) return;
    setIsDraggingNew(true);
    setStartXNew(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeftNew(scrollRef.current.scrollLeft);
  };
  const handleMouseLeaveNew = () => {
    setIsDraggingNew(false);
  };
  const handleMouseUpNew = () => {
    setIsDraggingNew(false);
  };
  const handleMouseMoveNew = (e: React.MouseEvent) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };
  return (
    <Box
      {...props}
      ref={scrollRef}
      sx={{
        display: 'flex',
        overflowX: 'auto',
        height: 380,
        gap: 2,
        padding: 2,
        cursor: isDragging ? 'grabbing' : 'grab',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
      }}
      onMouseDown={handleMouseDownNew}
      onMouseLeave={handleMouseLeaveNew}
      onMouseUp={handleMouseUpNew}
      onMouseMove={handleMouseMoveNew}
    >
      {children}
    </Box>
  );
};

export default SupraTVHorizontalScroll;