import { createTheme } from '@mui/material/styles';

export const SupraTVTheme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    width: '100%',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    marginTop: '0px',
                },
            },
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
});
